<template>
  <div>
    <v-card width="1000" class="mx-auto">
      <v-card-title>
        <span class="headline">
          <b>تعديل مهمة</b>
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 sm6>
                <v-text-field
                  label="اسم المهمة"
                  placeholder="اسم المهمة"
                  v-model="task.name"
                  :rules="nameRules"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6>
                <v-text-field
                  placeholder="نوع المهمة"
                  v-model="selectedTaskType"
                  label="نوع المهمة"
                  required
                  readonly
                ></v-text-field>
              </v-flex>
              <!--   <v-flex xs12 sm6>
                          <v-select
                            v-model="selectedTaskStatus"
                            :items="TaskStatus"
                            item-text="item"
                            item-value="value"
                            label="حالة المهمة"
                            :rules="TaskStatusRules"
                            persistent-hint
                            return-object
                            required
                          ></v-select>
                        </v-flex>-->
              <v-flex xs12 sm6>
                <v-text-field
                  label="المستخدم المنشئ"
                  placeholder="المستخدم المنشئ"
                  v-model="user.fullName"
                  required
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6>
                <v-select
                  v-model="ResponsibleId"
                  :items="ResponsibleUsers"
                  item-text="item"
                  item-value="value"
                  label="المستخدم الموكلة له"
                  :rules="usersRules"
                  persistent-hint
                  return-object
                  required
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6>
                <template>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="date"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="task.startDate"
                            label="تاريخ البداية"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="task.startDate" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal = false">
                            إغلاق
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(date)"
                          >
                            حفظ
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </template>
              </v-flex>
              <v-flex xs12 sm6>
                <template>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-dialog
                        ref="dialog2"
                        v-model="modal2"
                        :return-value.sync="date2"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="task.endDate"
                            label="تاريخ النهاية"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="task.endDate" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal2 = false">
                            إغلاق
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog2.save(date2)"
                          >
                            حفظ
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </template>
              </v-flex>
              <v-flex xs12 sm12>
                <v-text-field
                  label="ملاحظات المهمة"
                  v-model="task.notes"
                  placeholder="ملاحظات المهمة"
                  required
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="editTask"
            style="background: #5867dd; color: white; margin-left: 5px"
            dark
          >
            <b>حفظ</b>
          </v-btn>
          <v-btn @click="$router.go(-1)" style="background: gray" dark>
            <b>رجوع</b>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      usersRules: [
        v => !!v || "المستخدم مطلوب",
        v => v.value != "0" || "الاختيار غير مقبول"
      ],
      TaskRepetitionRules: [v => v.value != "0" || "الاختيار غير مقبول"],
      TaskStatusRules: [
        v => !!v || "حالة المهمة مطلوبة",
        v => v.value != "0" || "الاختيار غير مقبول"
      ],
      date: new Date().toISOString().substr(0, 10),
      date2: new Date().toISOString().substr(0, 10),
      modal: false,
      modal2: false,
      repeat: false,
      users: [],
      ResponsibleUsers: [{ item: "المستخدم الموكلة له المهمة", value: "0" }],
      selectedTaskStatus: 0,
      TaskStatus: [
        { value: "0", item: "اختر حالة المهمة" },
        { value: "5", item: "بانتظار المراجعة" },
        { value: "2", item: "قيد التقدم" },
        { value: "3", item: "منجزة" },
        { value: "4", item: "موكلة" }
      ],
      nameRules: [
        v => !!v || "الاسم مطلوب",
        v => (v && v.length >= 3) || "الاسم لا يجب أن يكون أقل من ثلاث محارف"
      ],
      selectedTaskTypeRules: [
        v => !!v || "نوع المهمة مطلوبة",
        v => v.value != "0" || "الاختيار غير مقبول"
      ],
      selectedTaskType: 0,
      selecyedTaskRepetition: 0,
      user: null,
      ResponsibleId: 0,
      creatorId: { value: "0", item: "--" },
      role: "",
      task: {
        endDate: "",
        idtask: "",
        name: "",
        notes: "",
        statusId: "",
        startDate: "",
        responsibleId: ""
      }
    };
  },
  methods: {
    async getTask(id) {
      await this.ApiService.get("Task/GetTask?id=" + id)
        .then(res => {
          this.task = res.data.responseData;
          const formatYmd = date => date.toISOString().slice(0, 10);
          this.task.startDate = formatYmd(new Date(this.task.startDate));
          this.task.endDate = formatYmd(new Date(this.task.endDate));
        })
        .catch(() => {});
    },
    editTask() {
      if (this.$refs.form.validate()) {
        var data = {
          Name: this.task.name,
          ResponsibleId: this.ResponsibleId.value,
          // CreatorId: this.creatorId.value,
          StatusId: this.selectedTaskStatus.value,
          Notes: this.task.notes,
          StartDate: this.task.startDate,
          EndDate: this.task.endDate
        };
        this.ApiService.put("task/UpdateTask/" + this.task.idtask, data)
          .then(() => {
            this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
            this.$router.go(-1);
          })
          .catch(() => {
            this.hel.showSnackMsg("فشلت العملية", "#af1616", 2000);
          });
      }
    },
    async getUsers() {
      this.users = [];
      this.ResponsibleUsers = [
        { item: "المستخدم الموكلة له المهمة", value: "0" }
      ];
      await this.ApiService.get("auth/listusers")
        .then(res => {
          this.users = res.data;
          this.users.forEach(el => {
            this.ResponsibleUsers.push({
              value: el.user.id,
              item: el.user.fullName
            });
          });
          this.users.forEach(el => {
            if (el.user.id == this.task.creatorId) {
              this.creatorId = { value: el.user.id, item: el.user.fullName };
            }
            if (el.user.id == this.task.responsibleId) {
              this.ResponsibleId = {
                value: this.task.responsibleId,
                item: el.user.fullName
              };
            }
          });
        })
        .catch(() => {});
      this.setTaskDetails();
    },
    setTaskDetails() {
      if (this.task.typeId == 2) {
        this.selectedTaskType = "تنبيه";
      } else if (this.task.typeId == 3) {
        this.selectedTaskType = "طلب معلومات ";
      } else {
        this.selectedTaskType = "زيارة ";
      }
      if (this.task.statusId == 5) {
        this.selectedTaskStatus = { value: "5", item: "بانتظار المراجعة" };
      } else if (this.task.statusId == 2) {
        this.selectedTaskStatus = { value: "2", item: "قيد التقدم" };
      } else if (this.task.statusId == 3) {
        this.selectedTaskStatus = { value: "3", item: "منجزة" };
      } else if (this.task.statusId == 4) {
        this.selectedTaskStatus = { value: "4", item: "موكلة" };
      }
    }
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");
    this.getTask(this.$route.params.taskId);
    this.getUsers();
  }
};
</script>
<style lang="scss"></style>
